<template>
  <div id="wrapper">
    <div class="map-container" ref="mapContainer" :style="{opacity: (step > 1 ? 1 : 0)}"/>
    <div class="search-results" v-if="searchResults && step == 1">
      <DocumentPreview
        v-for="result in searchResults"
        :document="result"
        :key="result.url"
        :on-click="() => url = result.url"
      />
    </div>
    <div class="side">
      <div class="side-heading">
        <h1>Point de vue<span>.gregoiredavid.fr</span></h1>
        <!-- <h1>Point de vue<span>.xyz</span></h1> -->
        <p>Géolocalisation de cartes postales et de photographies anciennes</p>
      </div>


      <div class="steps" v-if="error !== false">
        <Step index="1" title="Choisir un document" :current="step" @switchstep="switchStep">
          <p>Indiquez dans le champ ci-dessous le lien du document <a href="https://gallica.bnf.fr/" target="_blank">Gallica BNF</a> ou <a href="https://yroise.biblio.brest.fr/" target="_blank">Yroise</a> à géolocaliser.</p>
          <input type="text" v-model="url" class="text-field" />
          <Loader v-if="url && document === null" />
          <DocumentPreview v-if="document" :document="document" />
          <div class="document-preview" v-if="url && document === false">Lien incorrect</div>
          <button class="btn" @click="step = 2" :disabled="document === null || document === false">Valider</button>
        </Step>
        <Step index="2" title="Définir un emplacement" :current="step" @switchstep="switchStep">
          <p>Double-cliquez sur la carte à l'emplacement souhaité pour positionner un marqueur.</p>
          <p>Vous pouvez vous déplacer dans la carte en cliquant sur celle-ci et en utilisant les flèches de votre clavier ou en déplaçant votre souris tout en maintenant le bouton enfoncé. Vous pouvez augmenter ou diminuer le zoom avec la molette de votre souris (scroll) ou à l'aide des icônes + et - situées en haut à droite de la carte.</p>
          <Video file="pointdevue-2" />
        </Step>
        <Step index="3" title="Choisir l'orientation du point de vue" :current="step" @switchstep="switchStep">
          <p>Une fois le marqueur positionné, déplacez votre souris autour de ce point pour définir l'orientation du point de vue. Lorsque l'angle du champ de vision vous convient, double-cliquez à l'emplacement du curseur pour sauvegarder.</p>
          <Video file="pointdevue-3" />
        </Step>
        <Step index="4" title="Enregistrer" :current="step" @switchstep="switchStep">
          <p>Pour sauvegarder votre proposition de géolocalisation, merci d'indiquer une adresse email de contact (à usage interne uniquement, elle ne sera jamais rendue publique)</p>
          <label class="label" for="name">Votre nom (ou pseudonyme) :</label>
          <input name="name" type="text" v-model="name" class="text-field">
          <label class="label" for="email" required>Adresse E-mail</label>
          <input name="email" type="email" v-model="email" class="text-field">
          <label class="label" for="notes">Remarques éventuelles</label>
          <textarea name="notes" rows="3">
          </textarea>
          <div class="checkbox-field">
            <input id="remember" type="checkbox" v-model="remember"> <label for="remember">Mémoriser mon nom et mon adresse e-mail sur cet ordinateur pour gagner du temps.<span>Pour des raisons de confidentialité <u>ne cochez pas</u> cette case si vous utilisez un ordinateur qui ne vous appartient pas (lieu de travail, médiathèque, cyber-café).</span></label>
          </div>
          <div class="btn submit-button" @click="submit" :disabled="!email" v-if="error !== true">Enregistrer</div>
          <div class="erreur" v-if="error === true">Une erreur s'est produite, votre géolocalisation n'a pu être sauvegardée</div>
        </Step>
      </div>
      <div v-else>
        <div class="merci">
          <div>Votre proposition de géolocalisation a bien été enregistrée, merci !</div>
          <br>
          <a v-if="redirect && referrer" :href="referrer" class="btn">Cliquez ici pour revenir au site précédent</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Step from './components/Step.vue'
import Video from './components/Video.vue'
import uuid from 'uuid/v4'
import DocumentPreview from './components/DocumentPreview.vue'
import Loader from './components/Loader.vue'

import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp';
import axios from 'axios';
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';

mapboxgl.workerClass = MapboxWorker;
mapboxgl.accessToken = 'pk.eyJ1IjoiZ3JlZ29pcmVkYXZpZCIsImEiOiJJeVdqS0t3In0.WniHU6EAaP3R4e6aOtqL4g';

export default {
  name: 'App',
  components: {
    Step,
    Video,
    DocumentPreview,
    Loader,
  },
  data() {
    return {
      remember: false,
      uuid: null,
      error: null,
      url: null,
      step: 1,
      map: null,
      radians: null,
      angle: null,
      lat: null,
      lng: null,
      currentPoint: null,
      marker: null,
      debug: null,
      preset: null,
      document: null,
      document_id: null,
      source: null,
      searchResults: null,
      email: null,
      name: null,
      notes: null,
      redirect: null,
      referrer: null,
      project: null,
    };
  },
  created() {
    if(window.location.search) {
      var searchParams = new URLSearchParams(window.location.search);
      if (searchParams.has('doc')) {
        this.url = searchParams.get('doc');
        this.step = 2;
      }
      if (searchParams.has('r')) {
        this.redirect = searchParams.get('r');
        this.referrer = document.referrer;
      }
    }
    if(window.location.pathname) {
      this.project = window.location.pathname.replace(new RegExp('/', 'g'), '');
    }
    this.remember = localStorage.getItem('remember', false);
    this.name = localStorage.getItem('name', null);
    this.email = localStorage.getItem('email', null);
    // this.searchRequest();

  },
  mounted() {
    if (this.project === 'brest') {
      this.preset = {
        center: [
          -4.4928, // lng
          48.3856, // lat
        ],
        zoom: 15,
        style: 'mapbox://styles/gregoiredavid/cknh6hycs3ag217o47s3nqrqx',
      }
    }
    else {
      this.preset = {
        center: [
          2.3333, // lng
          48.8666, // lat
        ],
        zoom: 5,
        style: 'mapbox://styles/mapbox/light-v10',
      }
    }
    this.map = new mapboxgl.Map({
      container: this.$refs.mapContainer,
      style: this.preset.style,
      center: this.preset.center,
      zoom: this.preset.zoom
    });
    this.map.on('load', this.onMapLoad);
  },
  methods: {
    switchStep(index) {
      index = parseInt(index);
      switch (index) {
        case 2:
          this.currentPoint = null;
          this.lat = null;
          this.lng = null;
          this.marker.remove();
          /* falls through */
        case 3:
          this.angle = null;
          this.radians = null;
      }
      this.step = index;
    },
    async searchRequest() {
      // let searchUrl = 'https://gallica.bnf.fr/SRU?operation=searchRetrieve&version=1.2&query=(gallica all ';
      // searchUrl += '"brest"';
      // searchUrl += ')&startRecord=1';
      // const response = await axios.get('gallica');
      // this.searchResults = response.data;
      this.searchResults = [];
    },
    async submit() {
      try {
        this.uuid = uuid();
        await axios.post('https://pointdevue-api.gregoiredavid.fr/save.php', {
          id: this.uuid,
          document_id: this.document_id,
          source: this.source,
          email: this.email,
          name: this.name,
          lat: this.lat,
          lng: this.lng,
          radians: this.radians,
          angle: this.angle,
          notes: this.notes,
          // project: (this.project) ? this.project : '',
        });
        if (this.remember) {
          localStorage.setItem('remember', this.remember);
          localStorage.setItem('email', this.email);
          localStorage.setItem('name', this.name);
        }
        else {
          localStorage.clear();
        }
        this.error = false;
      } catch(error) {
        this.error = true;
      }
    },
    async fetchGallicaDocument() {
      const requestUrl = 'https://gallica.bnf.fr/iiif/' + this.document_id + '/manifest.json';
      try {
        const response = await axios.get(requestUrl);
        this.document = {
          title: response.data.description,
          source: response.data.label,
          thumb: response.data.thumbnail['@id'],
        }
      } catch(error) {
        this.document = false;
      }
    },
    async fetchDocument() {
      if (this.isValidHttpUrl(this.url)) {
        //eslint-disable-next-line
        const regex = /(?:http:|https:\/\/)\S+(ark:\/\d+\/\w+)\S*/;
        const found = this.url.match(regex);
        if (found) {
          this.document_id = found[1];
          this.source = new URL(this.url).hostname;
          switch (this.source) {
            case 'www.collections.musee-bretagne.fr':
              break;
            default:
              this.fetchGallicaDocument();
          }
        }
        /*
        const params = {
          ark: encodeURIComponent('ark:/12148/btv1b101061206'),
          vue: 'f1',
          region: 'full',
          size: encodeURIComponent('50,'),
          rotation: '0',
          quality: 'native',
        }
        console.log(params);
        // let requestUrl = 'https://gallica.bnf.fr/iiif/' + params.ark + '/' + params.vue + '/' + params.region + '/' + params.size + '/' + params.rotation + '/' + params.quality;
        // let requestUrl = 'https://gallica.bnf.fr/iiif/' + params.ark + '/' + params.vue + '/info.json';
        let requestUrl = 'https://gallica.bnf.fr/iiif/' + params.ark + '/manifest.json';
        // let requestUrl = 'https://yroise.biblio.brest.fr/ark:/12148/btv1b101061206.json';
        console.log('requestUrl', requestUrl)
        const response = await axios.get(requestUrl);
        this.document = {
          title: response.data.description,
          source: response.data.label,
          thumb: response.data.thumbnail['@id'],
        }
        console.log('response', response);
        */
      }
      this.document = false;
    },
    isValidHttpUrl(string) {
      let url;

      try {
        url = new URL(string);
      } catch (_) {
        return false;
      }

      return url.protocol === "http:" || url.protocol === "https:";
    },
    onMapLoad() {
      this.map.addControl(new mapboxgl.NavigationControl());
      // create DOM element for the marker
      this.markerEl = document.createElement('div');
      this.markerEl.id = 'marker';

      this.map.getCanvas().style.cursor = 'pointer';

      // right click.
      this.map.on('contextmenu', this.onRightClick);
      this.map.on('dblclick', this.onDblClick);
      this.map.on('mousemove', this.onMouseMove);
    },
    onRightClick(e) {
      e.preventDefault();
    },
    onMouseMove(e) {
      // this.debug = JSON.stringify(e.point) + '<br />' + JSON.stringify(e.lngLat.wrap());
      if (this.step == 3 && this.marker) {
        this.radians = Math.atan2(e.point.y - this.currentPoint.y, e.point.x - this.currentPoint.x);
        let angle = this.radians * 180 / Math.PI;
        this.angle = Math.round(angle * 100) / 100
        // this.debug += '<br>angle ' + angle + '°';
        this.marker.setRotation(this.angle);
      }
    },
    onDblClick(e) {
      e.preventDefault();
      switch (this.step) {
        case 2:
          this.marker = new mapboxgl.Marker(this.markerEl)
              .setLngLat(e.lngLat)
              .addTo(this.map);
          this.lat = e.lngLat.lat;
          this.lng = e.lngLat.lng;
          this.currentPoint = e.point;
          this.step = 3;
          break;
        case 3:
          this.step = 4;
          break;
      }
    },
  },
  watch: {
    url() {
      this.document = null;
      // setTimeout(this.fetchDocument, 1000);
      this.fetchDocument();
    }
  }
}
</script>
