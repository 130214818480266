<template>
  <div class="video">
    <div class="btn btn-info" @click="open = !open">
      <div class="btn-content">
        <template v-if="!open">
          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#999"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M10 8.64L15.27 12 10 15.36V8.64M8 5v14l11-7L8 5z"/></svg>
          <div>Démonstration vidéo</div>
        </template>
        <template v-else>
          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#999"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"/></svg>
          <div>Fermer</div>
        </template>
      </div>
    </div>
    <video autoplay muted loop v-if="open">
      <source :src="'/assets/' + file + '.mp4'" type="video/mp4">
      <source :src="'/assets/' + file + '.webm'" type="video/webm">
      <p>Votre navigateur ne prend pas en charge les vidéos HTML5. Voici <a :href="'/assets/' + file + '.mp4'">un lien pour télécharger la vidéo</a>.</p>
    </video>
  </div>
</template>

<script>

export default {
  name: 'Video',
  props: ['file'],
  data() {
    return {
      open: false,
    }
  },
}
</script>
