<template>
  <div class="document-preview" @click="click">
    <img :src="document.thumb" class="document-preview-thumb" />
    <div class="document-preview-content">
      <div class="document-preview-title">{{ document.title }}</div>
      <div class="document-preview-source">{{ document.source }}</div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'DocumentPreview',
  props: ['document', 'onClick'],
  components: {
    // HelloWorld
  },
  data() {
    return {
      active: null,
    };
  },
  methods: {
    click() {
      if (this.onClick) {
        this.onClick();
      }
    }
  },
  computed: {
  },
  created() {
  },
  mounted() {
  }
}
</script>
