<template>
  <div :class="{step: true, 'is-active': isActive, 'is-checked': isChecked}">
    <div class="step-index">
      <span>{{ index }}</span>
      <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="#ffffff">
        <path d="M0 0h24v24H0V0z" fill="none"/><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/>
      </svg>
    </div>
    <div class="step-title">
      {{ title }}
      <div class="edit btn btn-info" v-if="isChecked" @click="editStep">Modifier</div>
    </div>
    <div class="step-content">
      <slot v-if="isActive"></slot>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Step',
  props: ['index', 'title', 'current'],
  data() {
    return {
      active: null,
    };
  },
  methods: {
    editStep() {
      this.$emit('switchstep', this.index);
    },
  },
  computed: {
    isChecked() {
      return (this.current > this.index);
    },
    isActive() {
      return this.index == this.current;
    }
    // HelloWorld
  },
  created() {
  },
  mounted() {
  }
}
</script>
