
<template>
  <div class="loader">
    <div></div>
    <div></div>
  </div>
</template>

<script>

export default {
  name: 'Loader',
  mounted() {
  }
}
</script>

<style type="text/css">
.loader {
  position: relative;
  height: 3em;
  margin-bottom: 1rem;
}

.loader div {
  display: inline-block;
  height: 3em;
  width: 3em;
  position: absolute;
  background: #ccc;
  border-radius: 50%;
  /*animation: ripple-loader 1s ease-out infinite; */
  animation: pulse 1.3s ease-in-out infinite;
}

.loader div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes pulse {
  0% { transform: scale(0); opacity: 0.8;}
  100% { transform: scale(1); opacity: 0;}
}
</style>
